.anchorsContainer1 {
  display: flex;
  flex-direction: column;
  a {
    margin: 10px;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */

    text-transform: uppercase;

    /* gray_link */

    color: #9a9a9a;
    &:hover {
      color: #51b554;
    }
    @media (max-width: 400px) {
      font-size: 13px;
    }
  }
}
