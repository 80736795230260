
.dropdown {
    width: 200px;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
    background-color: white;
    @media (max-width: 998px) {
        align-self: center;
    }
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-size: 17px;
    line-height: 21px;
    color: #9a9a9a;

}

.dropdown-header {
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-body {
    padding: 5px;
    width: 190px;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
    display: none;
    position: absolute;
    @media (max-width: 998px) {
        background: white;
    }
}

.dropdown-body.open {
    display: block;
}

.dropdown-item {
    padding: 10px;
    @media (max-width: 998px) {
        border-bottom: 2px solid #e2e2e2;
    }
}

.dropdown-item:hover {
    cursor: pointer;
}

.dropdown-item-dot {
    opacity: 0;
    color: green;
    transition: all .2s ease-in-out;
}

.dropdown-item-dot.selected {
    opacity: 1;
}

.icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
}

.icon.open {
    transform: rotate(90deg);
}