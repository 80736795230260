
.cardContainer {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  padding: 20px 40px 40px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-right: 40px;
  width: 400px;
  height: 240px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.images {
  width:auto;
  height:70px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: "Montserrat", sans-serif;
  padding: 10px;
  font-family: Russo One;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-transform: uppercase;
}
.number {
  font-size:35px;
}



.imgfull {
  width:100%;
  height:100%;
}