.BackToUs1 {
  margin-bottom: 185px;
  margin-top:100px;
  @media (max-width: 1000px) {
    margin-top:0;
  }
  .BackContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1151px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    h3 {
      font-family: Russo One;
      font-style: normal;
      font-weight: normal;
      width: 400px;
      height: 98px;
      font-size: 38px;
      line-height: 130%;
      /* or 49px */

      text-transform: uppercase;

      /* dark */

      color: #2e302e;
      @media (max-width: 1267px) {
        font-size: 30px;
      }
      @media (max-width: 1151px) {
        font-size: 38px;
      }
      @media (max-width: 429px) {
        font-size: 30px;
      }
    }

    .imageContainer {
      display: flex;
      align-items: center;
      flex-direction: row;
      @media (max-width: 1000px) {
        flex-direction: column;
      }
      @media (max-width: 751px) {
        flex-direction: column;
      }

      .imageList {
        display: flex;
        margin: 10px;
        flex-direction: column;

        .back {
          margin: 10px;
          width: 400px;
          height: 241px;
          @media (max-width: 1267px) {
            width: 350px;
            height: 191px;
          }
          @media (max-width: 1151px) {
            width: 410px;
            height: 251px;
          }
          @media (max-width: 429px) {
            width: 310px;
            height: 171px;
          }

          div {

            display: flex;
            align-items: center;
            justify-content: center;

            .title1 {
              font-family: "Montserrat", sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              width: 90%;
              line-height: 145%;
              /* or 33px */

              /* dark */

              color: #2e302e;
              @media (max-width: 1267px) {
                font-size: 15px;
              }
              @media (max-width: 1151px) {
                font-size: 15px;
              }
              @media (max-width: 429px) {
                font-size: 12px;
               }
            }

            .text1 {
              font-family: "Montserrat", sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 150%;
              /* or 25px */

              /* dark */

              color: #2e302e;

              opacity: 0.7;
              @media (max-width: 1267px) {
                font-size: 14px;
              }
              @media (max-width: 429px) {
                font-size: 15px;
                text-align: left;
                width: 150px !important;
              }
            }
          }
        }
      }
    }
  }
}

//.img1Back {
//  background-image: url("../../../assets/ad1.png");
//  background-position: center;
//  background-size: contain;
//  background-repeat: no-repeat;
//}
//.img2Back {
//  background-image: url("../../../assets/pick.png");
//  background-position: center;
//  background-size: contain;
//  background-repeat: no-repeat;
//}
//.img3Back {
//  background-image: url("../../../assets/pick3.png");
//  background-position: center;
//  background-size: contain;
//  background-repeat: no-repeat;
//}
//.img4Back {
//  background-image: url("../../../assets/pick4.png");
//  background-position: center;
//  background-size: contain;
//  background-repeat: no-repeat;
//}

.imagelist2 {
  @media (max-width: 751px) {
    position: relative;
    top: -20px;
  }
}

.img1Back1 {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 241px;
  background-color: #F1F1F1;
  border-radius: 50px;
}

.backUs-image {
  width: 400px;
  height: 400px;
  @media (max-width: 500px) {
    width: 300px;
    height: 300px;
  }
}