.BackToUs {
  margin-bottom: 185px;
  .BackContainer {
    display: flex;
    flex-direction: row;
    @media (max-width: 1151px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    h3 {
      font-family: Russo One;
      font-style: normal;
      font-weight: normal;
      width: 400px;
      height: 98px;
      font-size: 38px;
      line-height: 130%;
      /* or 49px */

      text-transform: uppercase;

      /* dark */

      color: #2e302e;
      @media (max-width: 1267px) {
        font-size: 30px;
      }
      @media (max-width: 1151px) {
        font-size: 38px;
      }
      @media (max-width: 429px) {
        font-size: 30px;
      }
    }
    .imageContainer {
      display: flex;

      flex-direction: row;
      @media (max-width: 751px) {
        flex-direction: column;
      }
      .imageList {
        display: flex;
        margin: 10px;
        flex-direction: column;
        .back {
          margin: 10px;
          width: 400px;
          height: 241px;
          @media (max-width: 1267px) {
            width: 350px;
            height: 191px;
          }
          @media (max-width: 1151px) {
            width: 410px;
            height: 251px;
          }
          @media (max-width: 429px) {
            width: 310px;
            height: 171px;
          }
          div {
            width: 200px;
            position: relative;
            top: 25px;
            left: 30px;
            @media (max-width: 1267px) {
              top: 30px;
              left: 30px;
            }
            @media (max-width: 429px) {
              top: 10px;
            }
            .title1 {
              font-family: "Montserrat", sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 25px;
              line-height: 145%;
              /* or 33px */

              /* dark */

              color: #2e302e;
              @media (max-width: 1267px) {
                font-size: 17px;
              }
              @media (max-width: 1151px) {
                font-size: 25px;
              }
              @media (max-width: 429px) {
                font-size: 18px;
                width: 120px;
                text-align: left;
              }
            }
            .text1 {
              font-family: "Montserrat", sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 150%;
              /* or 25px */

              /* dark */

              color: #2e302e;

              opacity: 0.7;
              @media (max-width: 1267px) {
                font-size: 14px;
              }
              @media (max-width: 429px) {
                font-size: 15px;
                text-align: left;
                width: 150px !important;
              }
            }
          }
        }
      }
    }
  }
}

.img1Back {
  background-image: url("../../../assets/ad1.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.img2Back {
  background-image: url("../../../assets/pick.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.img3Back {
  background-image: url("../../../assets/pick3.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.img4Back {
  background-image: url("../../../assets/pick4.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.imagelist2 {
  @media (max-width: 751px) {
    position: relative;
    top: -20px;
  }
}
