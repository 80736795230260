.Cars1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 185px;

  justify-content: center;
  @media (max-width: 676px) {
    margin-bottom: 30px;
  }

  h3 {
    font-family: Russo One;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 130%;
    /* or 49px */

    text-transform: uppercase;

    /* dark */

    color: #2e302e;
    @media (max-width: 384px) {
      font-size: 30px;
    }
  }

  .carsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }

    .big-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      @media (max-width: 1400px) {
        flex-direction: column;
        // align-items: center;  align-items: center;
        justify-content: space-around;
        width: 100%;
      }
      @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
      }

      .block {
        display: flex;
        flex-direction: row;
        // width: 100%;
        // align-items: center;
        // width: 320px;
        // justify-content: center;
        @media (max-width: 1200px) {
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 700px) {
          flex-direction: column;
          // align-items: center;
        }

        .carBlock {
          padding: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 550px;
          @media (max-width: 582px) {
            width: 80%;

          }
          //width: 320px;
          h3 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 145%;
            /* identical to box height, or 33px */
            text-align: left;
            /* dark */
            color: #2e302e;

            @media (max-width: 582px) {
              width: 80%;
              font-size: 13px;
            }
            @media (max-width: 1330px) {
              font-size: 18px;
            }
          }

          .textCont {
            margin-top: 10px;
            margin-left: 30px;
            border-left: 4px solid #e2e2e2;
            width:100%;
            @media (max-width: 582px) {
              width: 320px;
            }
            p {
              padding-left: 5px;
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              margin: 0;
              align-self: flex-start;
              line-height: 150%;
              /* identical to box height, or 25px */
              text-align: left;

              /* dark */

              color: #2e302e;

              opacity: 0.7;
              @media (max-width: 582px) {
                font-size: 13px;
              }
              @media (max-width: 1330px) {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}

.carImg1 {
  //width: 290px;
  //height: 232px;
  width: 518px;
  height: 217px;
  display: flex;
  align-items: center;
  justify-content: center;
  border:3px solid #f1f1f1;
  border-radius: 20px;
  overflow: hidden;
  @media (max-width: 582px) {
    width: 320px;
    height: 120px;
  }
  img {
    width: 90%;
    height: 80%;
    //width: 230px;
    //height: 162px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      // padding: 10px;
    }
  }
}

//
//.img1CAR {
//  width: 300px !important;
//  height: 162px;
//}
//
//.img2CAR {
//  width: 300px !important;
//  height: 202px !important;
//}

// .tractor {
//   @media (max-width: 1300px) {
//     position: relative;
//     left: -25px;
//   }
//   @media (max-width: 700px) {
//     left: 0;
//   }
// }

//.car2 {
//  width: 250px !important;
//}
//
//.car3 {
//  width: 250px !important;
//}
//
//.car7 {
//  width: 250px !important;
//}
