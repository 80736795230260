
.quote {
    margin-top: 100px;
    width: 60%;
    font-style: italic;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    color: #aaaaaa;
    border-left: 4px solid #e2e2e2;
}