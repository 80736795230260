.Popup {
  display: flex;
  justify-content: center;
    @media (max-width: 615px) {
    padding: 20px;
  }
  .PopupContainer {
    display: flex;
    width: 90%;

    flex-direction: row;

    .PopupLinks {
      margin-top: 70px;
      display: flex;
      flex-direction: column;
      @media (max-width: 488px) {
        margin-top: 0px;
      }
      h1 {
        font-family: Russo One;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 130%;
        width: 100%;
        text-align: left;
        text-transform: uppercase;
        color: #2e302e;
        @media (max-width: 488px) {
          font-size: 25px;
          text-align: center;
        }
      }
      .textPopup {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 150%;
        text-align: left;
        color: #2e302e;
        opacity: 0.7;
        @media (max-width: 488px) {
          text-align: center;
        }
      }
      .Links {
        display: flex;
        flex-direction: column;
        justify-self: left;
        text-align: left;
        align-items: flex-start;
        @media (max-width: 488px) {
          align-items: center;
        }
        div {
          display: flex;
          flex-direction: row;
          justify-content: center;

          @media (max-width: 615px) {
            flex-direction: column;
          }
          span {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 23px;
            line-height: 145%;
            margin: 20px;
            margin-left: 0 !important;
            color: #2e302e;
            cursor: pointer;
            &:hover {
              color: #51b554;
            }
            @media (max-width: 488px) {
              font-size: 18px;

              text-align: center;
            }
          }
        }
      }
    }
  }
}

.tg a {
  color: #0088cc !important;
}
.whatsapp a {
  color: #25d366 !important;
}
.viber a {
  color: #59267c !important;
}

.popup-content {
  background: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  // border: 1px solid  black;
  width: 600px !important;
  height: 600px !important;
  @media (max-width: 615px) {
    width: 85% !important;
    height: auto !important;
  }
}

.log-form {
  width: 40%;
  min-width: 320px;
  max-width: 475px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.log-form form {
  display: block;
  width: 100%;
  padding: 2em;
}

.log-form h2 {
  width: 100%;
  color: #5d5d5d;
  font-family: "open sans condensed";
  font-size: 1.35em;
  display: block;
  background: #2a2a2a;
  width: 100%;
  text-transform: uppercase;
  padding: 0.75em 1em 0.75em 1.5em;
  -webkit-box-shadow: inset 0px 1px 1px fadeout(white, 95%);
  box-shadow: inset 0px 1px 1px fadeout(white, 95%);
  border: 1px solid #1d1d1d;
  margin: 0;
  font-weight: 200;
}

.log-form input {
  display: block;
  margin: auto auto;
  width: 100%;
  margin-bottom: 2em;
  padding: 0.5em 0;
  border: none;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 1.25em;
  color: #757575;
}

.log-form input:focus {
  outline: none;
}

.log-form .btn {
  display: inline-block;
  background: #1fb5bf;
  border: 1px solid #1ba0a9;
  padding: 0.5em 2em;
  color: white;
  margin-right: 0.5em;
  -webkit-box-shadow: inset 0px 1px 0px fadeout(white, 80%);
  box-shadow: inset 0px 1px 0px fadeout(white, 80%);
}

.log-form .btn:hover {
  background: #23cad5;
}

.log-form .btn:active {
  background: #1fb5bf;
  -webkit-box-shadow: inset 0px 1px 1px fadeout(black, 90%);
  box-shadow: inset 0px 1px 1px fadeout(black, 90%);
}

.log-form .btn:focus {
  outline: none;
}

.log-form .forgot {
  color: #33d3de;
  line-height: 0.5em;
  position: relative;
  top: 2.5em;
  text-decoration: none;
  font-size: 0.75em;
  margin: 0;
  padding: 0;
  float: right;
}

.log-form .forgot:hover {
  color: #1ba0a9;
}
/*# sourceMappingURL=main.css.map */

.href-popup {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  span {
    color: #ffffff !important;
  }
}

.mainButton123 {
  /* green */
  position: relative;
  left: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  margin-left: -67px;
  cursor: pointer;
  height: 72px;
  background: #51b554;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  @media (max-width: 488px) {
    margin-left: -130px;
  }
  @media (max-width: 374px) {
    width: 240px;
  }
}

.tg {
}

.hoverMail {
  &:hover {
    color: #2e302e !important;
  }
}
