@import "~react-image-gallery/styles/css/image-gallery.css";
.Portfolio {
  max-width: 1290px;
  margin-bottom: 150px;
  @media (max-width: 1010px) {
    margin-bottom: 50px;
  }
  .PortfolioContainer {
    display: flex;
    flex-direction: column;

    h2 {
      align-items: flex-start;
      justify-self: flex-start;
      font-family: Russo One;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 130%;
      /* or 49px */
      text-align: left;
      text-transform: uppercase;
      position: relative;
      left: 20px; /* dark */
      align-self: center;
      color: #2e302e;
      @media (max-width: 1300px) {
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @media (max-width: 425px) {
        font-size: 28px;
      }
    }
  }
}

// .swiper-wrapper {
//   width: 100%;

//   height: 350px;
// }

.slide {
  // width: 510px !important;
  height: 250px !important;

  /* gray_bg */
  margin: 10px;
  background: #f1f1f1;
  border-radius: 30px;
  @media (max-width: 1230px) {
    width: 90% !important;
    height: 80% !important;
  }
  @media (max-width: 1024px) {
    width: 700px !important;
    height: 400px !important;
  }
  @media (max-width: 768px) {
    width: 500px !important;
    height: 320px !important;
  }
  img {
    width: 100%;
    height: 100% !important;
    background: #f1f1f1;
    border-radius: 30px;
  }
}

.swiper-wrapper {
  border-radius: 30px;
}

.DesctopPort {
  @media (max-width: 615px) {
    // display: none !important;
  }
}

.react-multi-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .MobilePort {
//   @media (min-width: 615px) {
//     display: none !important;
//   }
// }

// .slideMob {
//   margin-bottom: 20px;
//   @media (max-width: 425px) {
//     width: 300px !important;
//     height: 170px !important;
//   }
// }

.react-multi-carousel-list {
  height: 400px !important;
}
