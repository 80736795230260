@mixin bullet($right) {
  position: absolute;
  top: 5px;
  right: -40px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #d8d8d8;
  border-radius: 50%;
}

.title {
  margin-bottom: 100px;
  align-items: flex-start;
  justify-self: flex-start;
  font-family: Russo One;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 130%;
  /* or 49px */
  text-align: left;
  text-transform: uppercase;
  position: relative;
  left: 20px; /* dark */
  align-self: center;
  color: #2e302e;
  @media (max-width: 1300px) {
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 425px) {
    font-size: 28px;
  }

  @media (max-width: 532px) {
    margin-bottom: 0;
  }
}

.range {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .rangeContainer {
    width: 80%;
    position: relative;
    @media (max-width: 1000px) {
      width: 70%;
    }
    @media (max-width: 532px) {
      display: none;
    }

    .range-slide {
      position: absolute;
      width: 100%;
      background: transparent;
      appearance: none;
      left: 0;
      top: -3px;
      z-index: 9;
      outline: none;
      opacity: 0;

      &::-ms-expand {
        display: none;
      }

      &::-ms-clear {
        display: none;
      }

      &::-webkit-slider-thumb {
        width: 35px;
        height: 35px;
        margin: -3px 0 0 -3px;
        cursor: pointer;
        appearance: none;
      }
    }

    .circle {
      position: absolute;
      top: -8px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: inline-block;
      transition: .2s;
    }

    .range-value {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 20%;
      height: 20px;
      background: green !important;
      border-radius: 10px 0 0 10px;
      transition: .2s;
    }


    &::before {
      @include bullet(6px);
      background: url("https://ibb.co/3B7LQcQ");
      content: 'JDK';
      margin-left: 10px;
      text-decoration: none;
      font-family: Russo One;
      color: green !important;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 21px;
      text-transform: uppercase;
      color: #9a9a9a;
    }

    .bullet {
      @include bullet(20%);

      &-1 {
        @include bullet(40%);
      }

      &-2 {
        @include bullet(60%);
      }

      &-3 {
        @include bullet(80%);
      }
    }
  }
}

.container {
  padding: 50px;
  text-align: center;

  h1 {
    margin: 0 0 40px;
  }
}

.cards {
  margin-top: 100px;
  @media (max-width: 532px) {
    display: none;
  }
}

.circle-image {
  background-repeat: no-repeat;
  display: flex;
  width: 50px;
  height: 50px;
  background-size: contain;
}

.road {
  width: 100%;
  display: block;
  height: 30px;
  border-bottom: 2px solid #e6e6e6;;
}

.circle-image::before {
  content: '';
  position: absolute;
  border: 1px solid green;
  left: -15px;
  right: -30px;
  top: -20px;
  bottom: -20px;
  border-radius: 50%;
  animation: pulse 1.8s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.pulse::after {
  animation-delay: .5s;
}

.rangeMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 532px) {
    margin-top: 50px;
  }
  @media (min-width: 533px) {
    display: none;
  }
}


.cards1 {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  padding: 20px 40px 40px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  width: 80%;
  margin-top: 10px;
  height: 240px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textcards1 {
  font-family: "Montserrat", sans-serif;
  padding: 10px;
  font-family: Russo One;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-transform: uppercase;
}


.imgcard {
  width: 50px;
  height: auto;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

$animation-delay: .1s;
$arrow-size: 12px;

.scroll-arrow {
  @media (min-width: 532px) {
    display: none;
  }
  width: $arrow-size;
  height: $arrow-size;
  transform: rotate(45deg);
  border-right: 2px solid green;
  border-bottom: 2px solid green;
  animation: arrow-wave 1s infinite;
  animation-direction: alternate;
  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation-delay: $animation-delay*$i;
    }
  }
}

@keyframes arrow-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}