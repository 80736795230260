.Header {
  margin-top: 30px;
  display: flex;
  align-items: center;
  max-width: 1290px;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
  .headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .anchorsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 700px) {
        display: none;
      }
      a {
        margin: 10px;
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        /* identical to box height */

        text-transform: uppercase;

        /* gray_link */

        color: #9a9a9a;
        &:hover {
          color: #51b554;
        }
        @media (max-width: 400px) {
          font-size: 13px;
        }
      }
    }
    .telContainer1 {
      display: flex;
      align-items: center;
      justify-content: center;
      //
      @media (max-width: 1000px) {
        margin-top: 20px;
      }
      //@media (max-width: 700px) {
      //  position: relative;
      //  right: -140px;
      //  top: 10px;
      //}
      //@media (max-width: 510px) {
      //  position: relative;
      //  right: -100px;
      //  top: 10px;
      //}
      //@media (max-width: 432px) {
      //  position: relative;
      //  right: -60px;
      //  top: 10px;
      //}
      span {
        margin: 10px;
        text-decoration: none;
        font-family: Russo One;
        color: green !important;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 21px;
        /* identical to box height */

        text-transform: uppercase;

        /* gray_link */

        color: #9a9a9a;
        @media (max-width: 360px) {
          font-size: 20px;
        }
      }
    }
  }
}
